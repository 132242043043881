@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Poppins:wght@100;300;400;500;600;700;800;900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Cairo";
  font-optical-sizing: inherit;
}

html {
  direction: rtl;
}

@layer components {

  .title {
    @apply font-bold text-3xl text-[#8C6633]
  }

  .h2 {
    @apply text-2xl pb-8 font-bold text-[#8C6633]
  }

  .btn-add {
    @apply text-white bg-[#1E7B75] hover:bg-green-900 rounded transition duration-300
  }

  .btn-edit {
    @apply text-indigo-600 border border-indigo-600 hover:bg-indigo-600 hover:text-white transition-colors duration-150 px-2 py-1 rounded ml-2
  }

  .input {
    @apply mt-1 block w-full rounded-lg border border-gray-300 p-2 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-2 focus:border-indigo-500
  }

  .input-invalid {
    @apply border-red-500 focus:ring-red-500 focus:border-red-500
  }

  .th-text {
    @apply px-6 py-3 text-right text-xs font-medium text-gray-500 tracking-wider
  }

  .td-text {
    @apply px-6 py-4 whitespace-nowrap text-sm text-gray-900
  }

  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .custom-scrollbar {
    box-sizing: border-box;
    padding-left: 5px;
    overflow-y: auto;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 4.3px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 10px;
  }

}