.froala-editor__toolbar {
    white-space: normal;  /* Allows toolbar buttons to wrap onto multiple lines if necessary */
}


@font-face {
    font-family: 'CairoExtraLight';
    src: url('../assets/fonts/Cairo-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'CairoLight';
    src: url('../assets/fonts/Cairo-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'CairoRegular';
    src: url('../assets/fonts/Cairo-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'CairoSemiBold';
    src: url('../assets/fonts/Cairo-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'CairoBold';
    src: url('../assets/fonts/Cairo-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'CairoExtraBold';
    src: url('../assets/fonts/Cairo-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'CairoBlack';
    src: url('../assets/fonts/Cairo-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'AmiriQuranRegular';
    src: url('../assets/fonts/AmiriQuran-Regular.ttf') format('truetype');
}







div.fr-wrapper > div:nth-child(1) > a {
    font-size: 0px !important;
    padding: 0px !important;
    height: 0px !important;
}

div.fr-wrapper>div>a {
    /* display: none !important; */
    /* position: fixed; */
    /* z-index: -99999 !important; */
    font-size: 0px !important;
    padding: 0px !important;
    height: 0px !important;
}
